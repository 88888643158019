import React, { useState } from 'react';
import CoFooter from './Append/CoFooter';
import CoHeader from './Append/CoHeader';
import IMP from './CoImpressum.module.scss';

const CoImpressum = ({ objGlobalState }) => {
  const [kostenlosTestenModalState, setKostenlosTestenModalState] = useState({
    open: false,
    paket: ''
  });

  return (
    <div className={IMP.root}>
      <CoHeader kostenlosTestenModalState={kostenlosTestenModalState} setKostenlosTestenModalState={setKostenlosTestenModalState} objGlobalState={objGlobalState} />
      <main className={IMP.main}>
        <section className={IMP.header}>
          <div className={IMP.header__container}>
            <h1>Impressum</h1>
          </div>
        </section>
        <section className={IMP.content}>
          <h3 className={IMP.content__header}>TracePort ist ein Produkt der CONVIDIUS business solutions GmbH</h3>
          <section className={IMP.content__upper}>
            <div className={IMP.first}>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Angaben gemäß § 5 TMG:</div>
                <div className={IMP.infoBox__content}>
                  <p>CONVIDIUS business solutions GmbH</p>
                  <p>Johannstr. 37</p>
                  <p>40476 Düsseldorf</p>
                </div>
              </div>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Firmensitz:</div>
                <div className={IMP.infoBox__content}>
                  <p>Düsseldorf</p>
                </div>
              </div>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Vertreten durch:</div>
                <div className={IMP.infoBox__content}>
                  <p>Thomas Hopp</p>
                </div>
              </div>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Kontakt:</div>
                <div className={IMP.infoBox__content}>
                  <p>Telefon: +49 511 878 125 0</p>
                  <p>EMail: info@convidius.de</p>
                </div>
              </div>
            </div>
            <div className={IMP.second}>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Registereintrag:</div>
                <div className={IMP.infoBox__content}>
                  <p>Eintragung im Handelsregister.</p>
                  <p>Registergericht: Hannover</p>
                  <p>Registernummer: HRB 219528</p>
                </div>
              </div>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Umsatzsteuer- ID:</div>
                <div className={IMP.infoBox__content}>
                  <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a</p>
                  <p>Umsatzsteuergesetz:</p>
                  <p>DE295306342</p>
                </div>
              </div>
            </div>
          </section>
          <section className={IMP.content__bottom}>
            <div className={IMP.sectionTitle}>
              <h2>Haftungsausschluss (Disclaimer)</h2>
            </div>
            <div className={IMP.sectionContent}>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Haftung für Inhalte</div>
                <div className={IMP.infoBox__content}>
                  <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                    jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                    entfernen.
                  </p>
                </div>
              </div>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Haftung für Links</div>
                <div className={IMP.infoBox__content}>
                  <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                    Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                    mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
                    ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                  </p>
                </div>
              </div>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Urheberrecht</div>
                <div className={IMP.infoBox__content}>
                  <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                    Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
                    privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                    werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                  </p>
                </div>
              </div>
              <div className={IMP.infoBox}>
                <div className={IMP.infoBox__title}>Bildnachweise</div>
                <div className={IMP.infoBox__content}>
                  <ul>
                    <li>Convidius GmbH</li>
                    <li>Fotos Oliver Vosshage</li>
                    <li>Fotos Nicole Hopp</li>
                    <li>Fotos AW Logistik Service GmbH</li>
                    <li>shutterstock_1331858669</li>
                    <li>shutterstock_1490010281</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </section>
      </main>
      <CoFooter />
    </div>
  );
};

export default CoImpressum;
