import React, { useState } from 'react';
import CoFooter from './Append/CoFooter';
import CoHeader from './Append/CoHeader';
import AGB from './CoAGB.module.scss';
import agbData from './agb.json';

const AGBSection = ({ title, content }) => {
  const renderContent = (item, i, arr) => {
    const key = `agb_agbSection-${title}-${i}`;
    const enumeration = arr.length ? `(${i + 1})` : '';
    const className = AGB.section__contentItem;

    if (item.type === 'textWithTitle') {
      return (
        <p key={key} className={className}>
          {enumeration} <span className={AGB.section__contentTextWithTitle__title}>{item.title}</span>: {item.text}
        </p>
      );
    }

    if (item.type === 'multipleTexts') {
      return (
        <div key={key} className={className}>
          {item.texts.map((text, i) => (
            <p key={`${key}-${i}`}>
              {i === 0 && enumeration} {text}
            </p>
          ))}
        </div>
      );
    }

    return (
      <p key={key} className={className}>
        {enumeration} {item.text}
      </p>
    );
  };

  return (
    <div className={AGB.section}>
      <div className={AGB.section__title}>{title}</div>
      <div className={AGB.section__content}>{content.map(renderContent)}</div>
    </div>
  );
};

const CoAGB = ({ objGlobalState }) => {
  const [kostenlosTestenModalState, setKostenlosTestenModalState] = useState({
    open: false,
    paket: ''
  });
  return (
    <div className={AGB.root}>
      <CoHeader kostenlosTestenModalState={kostenlosTestenModalState} setKostenlosTestenModalState={setKostenlosTestenModalState} objGlobalState={objGlobalState} />
      <main className={AGB.main}>
        <section className={AGB.content}>
          <div className={AGB.header}>
            <h2>Allgemeine Geschäftsbedingungen CONVIDIUS business solutions GmbH TracePort Software-as-a-Service-Angebot</h2>
          </div>
          <div className={AGB.introduction}>
            <div className={AGB.introduction__header}>Verantwortlich für den Inhalt</div>
            <p className={AGB.introduction__content}>CONVIDIUS business solutions GmbH, Johannstr. 37, 40476 Düsseldorf, nachfolgend CONVIDIUS genannt.</p>
          </div>
          {agbData.map(({ title, content }, i) => (
            <AGBSection title={title} content={content} key={`agb_agbSection-${i}`} />
          ))}
        </section>
      </main>
      <CoFooter />
    </div>
  );
};

export default CoAGB;
